<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
	<div class="card">
		<p-toolbar styleClass="p-mb-4">
			<div class="p-toolbar-group-left">
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;">
						<label for="filter_customer">Customer</label>
						<p-dropdown [options]="customersListFilter" #dropdown_customers placeholder="Select customer"
						(onChange)="getList(null)" [showClear]="true"
						[style]="{'min-width':'100%'}" optionLabel="companyName" name="customers" optionValue="id"></p-dropdown>
					</div>
					<div>
						<label for="filter_enabled">Enabled</label>
						<p-dropdown [options]="enabledListFilter" #dropdown_enabled [showClear]="true"
						[style]="{'min-width':'100%'}" placeholder="Select enabled" (onChange)="getList(null)"
						optionLabel="label" name="enabled" optionValue="value"></p-dropdown>
					</div>
				</div>
			</div>
			<div class="p-toolbar-group-right">
				<button pButton *ngIf="userHasPermission('CAN_ADD_NEW_INSTANCE')" label="Create Service Instance" (click)="createServiceInstance()" class="p-button-oris"></button>
			</div>
		</p-toolbar>
		<p-table #dtServiceInstance [value]="serviceInstance_list" dataKey="id" styleClass="p-datatable-oris"
			[rowHover]="true" [globalFilterFields]="['name', 'customer.companyName', 'vatNumber']"
			(onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
			[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="serviceInstanceListLoading">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
					Service Instance List
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text"
							(input)="dtServiceInstance.filterGlobal($event.target.value, 'contains')"
							placeholder="Global Search" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="name">
						<div class="p-d-flex p-jc-between p-ai-center">
							Name
							<div>
								<!-- <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> -->
								<p-sortIcon field="name"></p-sortIcon>
							</div>
						</div>
					</th>
					<th pSortableColumn="guid">
						<div class="p-d-flex p-jc-between p-ai-center">
							GUID
							<div>
								<p-sortIcon field="guid"></p-sortIcon>
							</div>
						</div>
					</th>
					<th pSortableColumn="customer_name">
						<div class="p-d-flex p-jc-between p-ai-center">
							Customer Name
							<div>
								<!-- <p-columnFilter type="text" field="customer_name" display="menu"></p-columnFilter> -->
								<p-sortIcon field="customer_name"></p-sortIcon>
							</div>
						</div>
					</th>
					<th pSortableColumn="start_date">
						<div class="p-d-flex p-jc-between p-ai-center">
							Start Date
							<div>
								<!-- <p-columnFilter type="date" field="start_date" display="menu"></p-columnFilter> -->
								<p-sortIcon field="start_date"></p-sortIcon>
							</div>
						</div>
					</th>
					<th pSortableColumn="end_date">
						<div class="p-d-flex p-jc-between p-ai-center">
							End Date
							<div>
								<!-- <p-columnFilter type="date" field="end_date" display="menu"></p-columnFilter> -->
								<p-sortIcon field="end_date"></p-sortIcon>
							</div>
						</div>
					</th>
					<th pSortableColumn="is_enabled">
						<div class="p-d-flex p-jc-between p-ai-center">
							Enabled
							<div>
								<!-- <p-columnFilter type="boolean" field="is_enabled" display="menu"></p-columnFilter> -->
								<p-sortIcon field="is_enabled"></p-sortIcon>
							</div>
						</div>
					</th>
					<th style="width: 8rem"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-instance>

				<tr class="p-selectable-row">
					<td>
						{{instance.name}}
					</td>
					<td>
						{{instance.guid}}
					</td>
					<td>
						{{instance.customer.companyName}}
					</td>
					<td>
						{{instance.startDate | date: 'dd/MM/yyyy'}}
					</td>
					<td>
						{{instance.endDate | date: 'dd/MM/yyyy'}}
					</td>
					<td>
						<div *ngIf="(instance.isEnabled)">
							<i class="pi pi-check"></i>
						</div>
					</td>
					<td>
						<button pButton type="button" title="Edit" icon="pi pi-pencil" *ngIf="userHasPermission('CAN_EDIT_INSTANCE_DATA')"
							(click)="editServiceInstance(instance.id)" class="p-button-rounded p-button-text"></button>
						<button pButton type="button" title="Delete" class="p-button-text"  *ngIf="userHasPermission('CAN_DELETE_INSTANCES')"
							(click)="deleteServiceInstace(instance.id, instance.name)" icon="pi pi-trash"></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td>No records found</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
<form #createServiceInstanceForm="ngForm" (ngSubmit)="createServiceInstanceForm.form.valid && saveServiceInstance()">
	<p-dialog header="New Service Instance" [(visible)]="serviceInstanceCreateModal" [modal]="true"
		[style]="{width: '800px'}" styleClass="p-fluid" [draggable]="false" [resizable]="false">
		<hr/>
		<h6 class="p-col-2 p-dialog-title info-paragraph">
			General
		</h6>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceNew_name">Instance Name</label>
				<input type="text" pInputText id="serviceInstanceNew_name" name="serviceInstanceNew_name"
					[(ngModel)]="serviceInstance_new.name" #name='ngModel' required />
				<div *ngIf="createServiceInstanceForm.submitted && name.invalid" class="error-login-info">
					<small *ngIf="name.errors?.required" class="p-error">Enter name</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceNew_vatNumber">Vat Number</label>
				<input type="text" pInputText id="serviceInstanceNew_vatNumber" name="serviceInstanceNew_vatNumber"
					[(ngModel)]="serviceInstance_new.vatNumber" #vat_number='ngModel' required />
				<div *ngIf="createServiceInstanceForm.submitted && vat_number.invalid" class="error-login-info">
					<small *ngIf="vat_number.errors?.required" class="p-error">Enter vat number</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceNew_isEnabled">Enabled</label>
				<div class="p-field-checkbox p-top-3">
					<p-checkbox name="serviceInstanceNew_isEnabled" binary="true" inputId="is_enabled"
						[(ngModel)]="serviceInstance_new.isEnabled"></p-checkbox>
				</div>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceNew_customer">Customer</label>
				<p-dropdown [options]="customersList" [(ngModel)]="serviceInstance_new.customerId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select customer" optionLabel="companyName"
					name="customers" [baseZIndex]="10001" required optionValue="id" #customer='ngModel'></p-dropdown>
				<div *ngIf="createServiceInstanceForm.submitted && customer.invalid" class="error-login-info">
					<small *ngIf="customer.errors?.required" class="p-error">Select customer</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceNew_startDate">Start Date</label>
				<p-calendar name="serviceInstanceNew_startDate" appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}" class='calender-overflow'
					dataType="date" dateFormat="dd/mm/yy" required #start_date='ngModel' [(ngModel)]="serviceInstance_new.startDate">
				</p-calendar>
				<div *ngIf="createServiceInstanceForm.submitted && start_date.invalid" class="error-login-info" style="margin-top: 2.3rem !important;">
					<small *ngIf="start_date.errors?.required" class="p-error">Select start date</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceNew_endDate">End Date</label>
				<p-calendar name="serviceInstanceNew_endDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy" required
					#end_date='ngModel' [(ngModel)]="serviceInstance_new.endDate">
				</p-calendar>
				<div *ngIf="createServiceInstanceForm.submitted && end_date.invalid" class="error-login-info" style="margin-top: 2.3rem !important;">
					<small *ngIf="end_date.errors?.required" class="p-error">Select end date</small>
				</div>
			</div>
		</div>
		<hr/>
		<h6 class="p-col-2 p-dialog-title info-paragraph">
			Manager Data
		</h6>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceNew_managerName">Name</label>
				<input type="text" pInputText id="serviceInstanceNew_managerName" name="serviceInstanceNew_managerName"
					[(ngModel)]="serviceInstance_new.managerName" #managerName='ngModel' required />
				<div *ngIf="createServiceInstanceForm.submitted && managerName.invalid" class="error-login-info">
					<small *ngIf="managerName.errors?.required" class="p-error">Enter username</small>
				</div>
			</div>
			<div class="p-col-6">
				<label for="serviceInstanceNew_managerSurname">Surname</label>
				<input type="text" pInputText id="serviceInstanceNew_managerSurname" name="serviceInstanceNew_managerSurname"
					[(ngModel)]="serviceInstance_new.managerSurname" #managerSurname='ngModel' required />
				<div *ngIf="createServiceInstanceForm.submitted && managerSurname.invalid" class="error-login-info">
					<small *ngIf="managerSurname.errors?.required" class="p-error">Enter surname</small>
				</div>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceNew_managerUsername">Username</label>
				<input type="text" pInputText id="serviceInstanceNew_managerUsername" name="serviceInstanceNew_managerUsername"
					[(ngModel)]="serviceInstance_new.managerUsername" #managerUsername='ngModel' required />
				<div *ngIf="createServiceInstanceForm.submitted && managerUsername.invalid" class="error-login-info">
					<small *ngIf="managerUsername.errors?.required" class="p-error">Enter username</small>
				</div>
			</div>
			<div class="p-col-6">
				<label for="serviceInstanceNew_managerPassword">Password</label>
				<input type="password" pInputText id="serviceInstanceNew_managerPassword" name="serviceInstanceNew_managerPassword"
					[(ngModel)]="serviceInstance_new.managerPassword" #managerPassword='ngModel' required />
				<div *ngIf="createServiceInstanceForm.submitted && managerPassword.invalid" class="error-login-info">
					<small *ngIf="managerPassword.errors?.required" class="p-error">Enter password</small>
				</div>
			</div>
		</div>
		<hr/>
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button"
				(click)="closeCreateServiceInstance()" class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>
<form #editServiceInstanceForm="ngForm" (ngSubmit)="editServiceInstanceForm.form.valid && updateServiceInstance()">
	<p-dialog header="Edit Service Instance" [(visible)]="serviceInstanceEditModal" [modal]="true"
		[style]="{width: '800px'}" styleClass="p-fluid" [draggable]="false" [resizable]="false">
		<h6 class="p-col-2 p-dialog-title info-paragraph">
			General
		</h6>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceEdit_name">Name</label>
				<input type="text" pInputText id="serviceInstanceEdit_name" name="serviceInstanceEdit_name"
					[(ngModel)]="serviceInstance_edit.name" #edit_name='ngModel' required />
				<div *ngIf="editServiceInstanceForm.submitted && edit_name.invalid" class="error-login-info">
					<small *ngIf="edit_name.errors?.required" class="p-error">Enter name</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceEdit_vatNumber">Vat Number</label>
				<input type="text" pInputText id="serviceInstanceEdit_vatNumber" name="serviceInstanceEdit_vatNumber"
					[(ngModel)]="serviceInstance_edit.vatNumber" #edit_vat_number='ngModel' required />
				<div *ngIf="editServiceInstanceForm.submitted && edit_vat_number.invalid" class="error-login-info">
					<small *ngIf="edit_vat_number.errors?.required" class="p-error">Enter vat number</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceEdit_isEnabled">Enabled</label>
				<div class="p-field-checkbox p-top-3">
					<p-checkbox name="serviceInstanceEdit_isEnabled" binary="true" inputId="is_enabled"
						[(ngModel)]="serviceInstance_edit.isEnabled"></p-checkbox>
				</div>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceEdit_customer">Customer</label>
				<p-dropdown [options]="customersList" [(ngModel)]="serviceInstance_edit.customerId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select customer" optionLabel="companyName"
					name="customers" [baseZIndex]="10001" required optionValue="id" #customerId='ngModel'></p-dropdown>
				<div *ngIf="editServiceInstanceForm.submitted && customerId.invalid" class="error-login-info">
					<small *ngIf="customerId.errors?.required" class="p-error">Select customer</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceEdit_startDate">Start Date</label>
				<p-calendar name="serviceInstanceEdit_startDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					required #edit_start_date='ngModel' [(ngModel)]="serviceInstance_edit.startDate">
				</p-calendar>
				<div *ngIf="editServiceInstanceForm.submitted && edit_start_date.invalid" class="error-login-info" style="margin-top: 2.3rem !important;">
					<small *ngIf="edit_start_date.errors?.required" class="p-error">Select start date</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="serviceInstanceEdit_endDate">End Date</label>
				<p-calendar name="serviceInstanceEdit_endDate"
					appendTo="body" [baseZIndex]="9999"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':9999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					required #edit_end_date='ngModel' [(ngModel)]="serviceInstance_edit.endDate">
				</p-calendar>
				<div *ngIf="editServiceInstanceForm.submitted && edit_end_date.invalid" class="error-login-info" style="margin-top: 2.3rem !important;">
					<small *ngIf="edit_end_date.errors?.required" class="p-error">Select start date</small>
				</div>
			</div>
		</div>
		<hr/>
		<h6 class="p-col-2 p-dialog-title info-paragraph">
			Manager Data
		</h6>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceEdit_managerName">Name</label>
				<input type="text" disabled="disabled" pInputText id="serviceInstanceEdit_managerName" name="serviceInstanceEdit_managerName"
					[(ngModel)]="serviceInstance_edit.managerName" #managerName='ngModel' />
			</div>
			<div class="p-col-6">
				<label for="serviceInstanceEdit_managerSurname">Surname</label>
				<input type="text" disabled="disabled" pInputText id="serviceInstanceEdit_managerSurname" name="serviceInstanceEdit_managerSurname"
					[(ngModel)]="serviceInstance_edit.managerSurname" #managerSurname='ngModel' />
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="serviceInstanceEdit_managerUsername">Username</label>
				<input type="text" disabled="disabled" pInputText id="serviceInstanceEdit_managerUsername" name="serviceInstanceEdit_managerUsername"
					[(ngModel)]="serviceInstance_edit.managerUsername" #managerUsername='ngModel' />
			</div>
			<div class="p-col-6">
				<label for="serviceInstanceEdit_managerPassword">Password</label>
				<input type="password" disabled="disabled" pInputText id="serviceInstanceEdit_managerPassword" name="serviceInstanceEdit_managerPassword"
					[(ngModel)]="serviceInstance_edit.managerPassword" #managerPassword='ngModel' />
			</div>
		</div>
		<hr/>
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditServiceInstance()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>