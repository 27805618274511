<div class="layout-menu-wrapper" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}" *ngIf="isSidebarActive"
     (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)" (mouseleave)="appMain.onSidebarMouseLeave($event)">
    <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()">

        <a href="#/home" class="app-name" *ngIf="!appMain.isSlim() || appMain.isMobile()">
           <h1 style="color:#fff;margin-top:10px;"><b>Philo</b><span style="font-weight: 100;">App</span></h1>
        </a> 
    </div>

    <div class="layout-menu-container">
        <div class="p-d-flex p-flex-column p-ml-4 p-mt-3 menu-title-label" style="color:#000;text-transform:uppercase; font-size: 18px;">Main Menu<div class="small-font" style="text-transform:initial;font-size: 16px;">Manage</div></div>
        <ul class="layout-menu">
            <ng-container *ngFor="let item of modelSystem; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
        <div class="logo-menu-alk">
            <img src="assets/images/logo-alkenium.svg">
        </div>
    </div>
</div>
