import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { Observable } from "rxjs";
import { BaseRequestMethod } from "src/app/core/base/base.service";
import { BaseService } from "src/app/core/base/base.service";
import { ExportFileType } from "src/app/enum/exportFileType.enum";

@Injectable()
export class ProductService {
    
    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getProductList(token:string, event:LazyLoadEvent, serviceInstanceId:string, collectionPlanId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "products", token, {
            filtersProductList: {
                serviceInstanceId: serviceInstanceId ?? null,
                collectionPlanId: collectionPlanId ?? null
            },
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }

    exportProduct(token:string, serviceInstanceId:string, productId:string, typeExport:ExportFileType):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, 'products/export/article/sheet/file', token,
        {
            extension: typeExport,
            serviceInstanceId: serviceInstanceId,
            id: productId
        });
    }
    
}