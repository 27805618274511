<div class="p-col-12">
    <div *ngIf="collectionPlanExportLoading">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left">
                <div class="p-d-flex p-flex-column p-flex-md-row">
                    <div style="margin-right: 2rem;">
                        <label for="filter_serviceIstances">Service istances</label>
                        <br />
                        <p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances
                            placeholder="Select service istance" (onChange)="getList(null)" [showClear]="true"
                            [style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </p-toolbar>
        <p-table #dtCollectionPlan [value]="collectionPlanList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
            [globalFilterFields]="['name', 'alkenium_number', 'customer', 'service_instance']" (onLazyLoad)="getList($event)"
            [lazy]="true" [totalRecords]="(totalRecords)" [rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0"
            [loading]="collectionPlanListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Collection Plan List
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtCollectionPlan.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Name
                            <p-sortIcon field="name"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="project">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Project
                            <p-sortIcon field="project"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="season">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Season
                            <p-sortIcon field="season"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="collection">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Collection
                            <p-sortIcon field="collection"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="serviceInstance" *ngIf="userHasPermission('CAN_EDIT_COLLECTIONPLAN')">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Service Instance
                            <p-sortIcon field="serviceInstance"></p-sortIcon>
                        </div>
                    </th>
                    <th style="width: 15rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                <tr class="p-selectable-row">
                    <td>
                        {{collectionPlan.header.name}}
                    </td>
                    <td>
                        {{collectionPlan.header.project}}
                    </td>
                    <td>
                        {{collectionPlan.header.season}}
                    </td>
                    <td>
                        {{collectionPlan.header.collection}}
                    </td>
                    <td *ngIf="userHasPermission('CAN_EDIT_COLLECTIONPLAN')">
                        {{collectionPlan.serviceInstanceName}}
                    </td>
                    <td>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EDIT_COLLECTIONPLAN')" title="Edit instance"
                            icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="editInstanceCollectionPlan(collectionPlan.id)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_COLLECTIONPLANS_LIST')" title="Show details"
                            icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                            (click)="showCollectionPlan(collectionPlan.id)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_PRODUCT_LIST')" title="Show products"
                            icon="pi pi-list" class="p-button-rounded p-button-text" (click)="showCollectionPlanProducts(collectionPlan.id)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_COLLECTIONPLAN')" title="Export Excel" class="p-button-text" icon="pi pi-file-excel"
                            (click)="openExportCollectionPlanModal(collectionPlan.id, collectionPlan.header.name, exportFileType.EXCEL)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_COLLECTIONPLAN')" title="Export Pdf"
                            class="p-button-text" icon="pi pi-file-pdf" (click)="openExportCollectionPlanModal(collectionPlan.id, collectionPlan.header.name, exportFileType.PDF)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<form #editInstanceCollectionPlanForm="ngForm" (ngSubmit)="editInstanceCollectionPlanForm.form.valid && updateInstanceCollectionPlan()">
    <p-dialog header="Edit Collection plan Instance association" [(visible)]="editInstanceCollectionPlanModal" [modal]="true" [style]="{width: '800px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <hr />
        <div class="p-col-12 instance-dialog-subtitle">
            Change the instance association for the collection
            <b *ngIf="collectionPlan_edit !== undefined && collectionPlan_edit.header !== undefined && collectionPlan_edit.header.name !== undefined">
                {{collectionPlan_edit.header.name}}
            </b>
        </div>
        <div class="p-formgroup-inline min-h-instance-dialog-body">
            <div class="p-col-6">
                <label for="collectionPlanEdit_serviceInstance">Select service instance</label>
                <p-dropdown appendTo="body" [options]="editServiceInstanceList" [(ngModel)]="collectionPlan_edit.serviceInstanceId"
                    [panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
                    name="editCollectionPlanServiceInstance" [baseZIndex]="10010" required optionValue="id"
                    #edit_service_instance='ngModel'>
                </p-dropdown>
            </div>
        </div>
        <hr />
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditInstanceCollectionPlan()"
                class="p-button-danger"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
        </ng-template>
    </p-dialog>
</form>
<form #exportCollectionPlanForm="ngForm" (ngSubmit)="exportCollectionPlan()">
    <p-dialog header="Export Collection plan" [(visible)]="exportCollectionPlanModal" [modal]="true" [style]="{width: '800px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <hr />
        <div class="p-formgroup-inline min-h-instance-dialog-body">
            <div class="p-col-6">
                <label for="collectionPlanEdit_serviceInstance">Select template export collection plan</label>
                <p-dropdown appendTo="body" [options]="exportOptionList" [(ngModel)]="selectedExportValue"
                    [panelStyle]="{'overflow-y':'auto'}" placeholder="Select export type" optionLabel="option"
                    name="exportOptionType" [baseZIndex]="10010" required optionValue="value">
                </p-dropdown>
            </div>
        </div>
        <hr />
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeExportCollectionPlanModal()"
                class="p-button-danger"></button>
            <button pButton pRipple label="Export" icon="pi pi-check" type="submit" class="p-button-oris"></button>
        </ng-template>
    </p-dialog>
</form>