import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';
import { ServiceInstance } from '../domain/instance';

@Injectable()
export class InstanceService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getServiceInstanceList(token:string, event:LazyLoadEvent, customerId:string, isEnabled:boolean):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "instances", token, {
            filters:event?.filters ?? null,
            filtersServiceInstances:{
                customerId: customerId ?? null,
                isEnabled: isEnabled ?? null
            },
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    };

    getServiceInstance(token:string, id:string): Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "instances/"+id, token, {});
    }

    createServiceInstance(token:string, serviceInstance:ServiceInstance): Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "instances/create", token,{
            name: serviceInstance.name,
            customerId: serviceInstance.customerId,
            vatNumber: serviceInstance.vatNumber,
            managerUserData: {
                name: serviceInstance.managerName,
                surname: serviceInstance.managerSurname,
                username: serviceInstance.managerUsername,
                email: serviceInstance.managerUsername,
                password: serviceInstance.managerPassword,
                usersGroupId: '',
                serviceInstanceId: null
            },
            startDate: serviceInstance.startDate ?? null,
            endDate: serviceInstance.endDate ?? null,
          });
    }

    updateServiceInstance(token:string, serviceInstance:ServiceInstance): Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, "instances/update", token,{
            id: serviceInstance.id,
            name: serviceInstance.name,
            customerId: serviceInstance.customerId,
            vatNumber: serviceInstance.vatNumber,
            isEnabled: serviceInstance.isEnabled,
            startDate: serviceInstance.startDate ?? null,
            endDate: serviceInstance.endDate ?? null,
            isSendServiceEnabled: serviceInstance.isSendServiceEnabled ?? false,
            isReceiverServiceEnabled: serviceInstance.isReceiverServiceEnabled ?? false
        });
    }

    deleteServiceInstance(token:string, id:string): Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, "instances/"+id, token, {});
    }

}