import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';
import { JobOrder } from '../domain/joborder';

@Injectable()
export class JobOrderService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getJobOrder(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "joborders/joborders/" + id, token, {});
    }

    getJobOrdersList(token:string, event:LazyLoadEvent, projectId: string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "joborders", token, {
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            filtersJobOrder: {
                ProjectId: projectId,
                type: 0
            },
            sortOrder:event?.sortOrder ?? null
        });
    }

    createJobOrder(token:string, jobOrder:JobOrder):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "joborders/create", token,{
            name: jobOrder.name,
            description: jobOrder.description,
            projectId: jobOrder.projectId,
            startTime: jobOrder.startDate,
            endTime: jobOrder.endDate,
            serviceInstanceId: jobOrder.serviceInstanceId
        });
    }

    updateJobOrder(token:string, jobOrder:JobOrder):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, "joborders/update", token,{
                id: jobOrder.id,
                name: jobOrder.name,
                description: jobOrder.description,
                projectId: jobOrder.projectId,
                startTime: jobOrder.startDate,
                endTime: jobOrder.endDate,
                serviceInstanceId: jobOrder.serviceInstanceId
            });
    }

    deleteJobOrder(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, "joborders/"+id, token, {});
    }
}