import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from 'src/app/app.globals';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { CustomerService } from '../customers/service/customerservice';
import { ServiceInstance } from '../instances/domain/instance';
import { InstanceService } from '../instances/service/instanceservice';
import { Project } from './domain/project';
import { ProjectService } from './service/projectservice';


@Component({
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})


export class ProjectComponent {
    private token: string;
    public project_new: Project = {};
    public project_edit: Project = {};
    public projectList: Project[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public projectCreateModal: boolean = false;
    public projectEditModal: boolean = false;
    public projectListLoading: boolean = true;
    public warningModal: boolean;
    public totalRecords: number;
    event_lazy_local:LazyLoadEvent;
    itemsPerPage: number = 25;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private projectService: ProjectService,
        private router:Router,
        private customerService: CustomerService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Project', '/projects', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        context.setServiceIstanceFilter();
        context.setClientsDropdown();
        context.setServiceInstancesDropdown();
    }

    public createProject() {
        let context = this;
        context.project_new = {} as Project;
        context.projectCreateModal = true;
    }

    setServiceIstanceFilter()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    private setClientsDropdown(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null) .pipe(
            tap((response) => {
                if(response.data != null){
                    context.clientsList = response.data.map((clients) => {
                        return {
                            id: clients.id,
                            name: clients.companyName
                        }
                });
            }
        }),catchError((error) => {return of();})).subscribe();
    }

    private setServiceInstancesDropdown()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    public editProject(id:string){
        let context = this;
        context.projectService.getProject(context.token, id).pipe(
            tap((response) => {
                context.project_edit = {
                    id: response.data.id,
                    name: response.data.name,
                    alkeniumNumber: response.data.alkeniumNumber,
                    customerId: response.data.customerId,
                    description: response.data.description,
                    startDate: (response.data.startTime != '' && typeof response.data.startTime !== undefined && response.data.startTime != null ? new Date(response.data.startTime) : null),
                    serviceInstanceId: response.data.serviceInstanceId,
                    endDate: (response.data.endTime != '' && typeof response.data.endTime !== undefined && response.data.endTime != null ? new Date(response.data.endTime) : null),
                } as Project;
                context.projectEditModal = true;
            }),catchError((error) => {
                context.messageService.add({
                    severity:"error",
                    summary:"Error",
                    detail:error.message ?? error.error.title
                });
                context.projectEditModal = false;
                return of();
        }),finalize(() => {})).subscribe();
    }

    closeCreateProject() {
        this.projectCreateModal = false;
    }

    closeEditProject()
    {
        this.projectEditModal = false;
    }

    public updateProject(){
        let context = this;
        context.projectService.updateProject(context.token, context.project_edit).pipe(
            tap((res) => {
                    if(res.data != null){
                        context.messageService.add({
                            severity:'success',
                            summary:'Success',
                            detail:res.message ?? "Success"
                        });

                        context.projectEditModal = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })

                    return of();
                }),
                finalize(()=>{
                    context.getList(null);
                    context.projectEditModal = false;
        })).subscribe();
    }

    public saveProject() {
        let context = this;
        context.projectService.createProject(context.token, context.project_new).pipe(
                tap((response) => {
                    if(response.data != null){
                        context.messageService.add({
                            severity:'success',
                            summary:'Success',
                            detail:response.message ?? "Success"
                        }); 
                    }
                }), catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    context.project_new = {} as Project;
                    return of();
                }),
                finalize(() => {
                    context.getList(null);
                    context.projectCreateModal = false;
                })
            )
            .subscribe();
    }

    public deleteProject(id: string, name:string) {
        let context = this;
        context.confirmationService.confirm({
            message: "You are about to delete '" + name + "'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                context.projectService.deleteProject(context.token, id).pipe(
                        tap((res) => {
                            context.messageService.add({
                                severity: "success",
                                summary: "Success",
                                detail: res.message ?? "Success"
                            });
                        }),
                        catchError((res) => {
                            context.messageService.add({
                                severity: "error",
                                summary: "Error",
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                            context.getList(null);
                        })
                    )
                    .subscribe();
            },reject: () => {
                return;
            },
            rejectVisible: true
        });
    }

    public openOrder(id){
        this.router.navigate(["/job-orders/"+id]);
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }
    
    public getList(event:LazyLoadEvent) {
        let context = this;
        if(event != null){
            context.event_lazy_local = event;
        }

        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;

        context.projectListLoading = true;
        context.projectService.getProjectList(context.token, context.event_lazy_local, serviceInstanceId)
            .pipe(
                tap((res) => {
                    if(res.data != null){
                        let slice = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;

                        context.projectList = slice.map((project) => {
                            return {
                                id: project.id,
                                name: project.name,
                                alkeniumNumber: project.alkeniumNumber,
                                startTime:
                                    typeof project.startTime !== "undefined" && project.startTime !== null
                                        ? project.startTime
                                        : "",
                                customer:
                                    typeof project.customer !== "undefined" && project.customer !== null &&
                                    typeof project.customer.companyName !== "undefined" && project.customer.companyName !== null
                                        ? project.customer.companyName
                                        : "",
                                serviceInstance:
                                    typeof project.instance !== "undefined" && project.instance !== null &&
                                    typeof project.instance.name !== "undefined" && project.instance.name !== null
                                        ? project.instance.name
                                        : "",
                                endTime:
                                    typeof project.endTime !== "undefined" && project.endTime !== null
                                        ? project.endTime
                                        : "",
                            };
                            
                        });
                        context.projectListLoading = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }
}