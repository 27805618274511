import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { of } from "rxjs";
import { NgForm } from "@angular/forms";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";
import { AppBreadcrumbService } from "src/app/core/breadcumb/app.breadcrumb.service";
import { CustomerService } from "../customers/service/customerservice";
import { ServiceInstance } from "../instances/domain/instance";
import { InstanceService } from "../instances/service/instanceservice";
import { CollectionPlan } from "./domain/collectionPlan";
import { CollectionPlanService } from "./service/collectionPlan.service";
import { CollectionPlanDetailsService } from "../collectionPlanDetails/service/collectionPlanDetails.service";
import { ExportFileType } from "src/app/enum/exportFileType.enum";
import { ExportOptions } from "../instances/domain/exportOptions";


@Component({
    templateUrl: "./collectionPlan.component.html",
    styleUrls: ["./collectionPlan.component.scss"],
})


export class CollectionPlanComponent {
    private token: string;
    public collectionPlan_edit: CollectionPlan = {};
    public collectionPlan_export: {
        id: string,
        headerName: string,
        typeExport: ExportFileType
    };
    public collectionPlanList: CollectionPlan[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public editServiceInstanceList: ServiceInstance[];
    public exportOptionList: ExportOptions[] = [
        {
            option : "Simple (Line Planning)",
            value : false
        },
        {
        option : "Full (Line Planning + Products)",
        value : true
    }];

    public selectedExportValue: boolean = false;
    public serviceInstancesFilter: ServiceInstance[];
    public collectionPlanListLoading: boolean = true;
    public collectionPlanExportLoading: boolean = false;
    public exportFileType = ExportFileType;
    public warningModal: boolean;
    public totalRecords: number;
    public editInstanceCollectionPlanModal: boolean = false;
    public exportCollectionPlanModal: boolean = false;
    event_lazy_local: LazyLoadEvent;
    itemsPerPage: number = 25;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;

    @ViewChild("editCollectionPlanForm")
    editCollectionPlanForm: NgForm;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private collectionPlanService: CollectionPlanService,
        private collectionPlanDetailsService: CollectionPlanDetailsService,
        private router:Router,
        private customerService: CustomerService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Collection Plans', '/collectionplans', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        context.setServiceIstanceFilter();
        context.setClientsDropdown();
        context.setServiceInstancesDropdown();
    }

    setServiceIstanceFilter()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    private setClientsDropdown(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null) .pipe(
            tap((response) => {
                if(response.data != null){
                    context.clientsList = response.data.map((clients) => {
                        return {
                            id: clients.id,
                            name: clients.companyName
                        }
                });
            }
        }),catchError((error) => {return of();})).subscribe();
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    private setServiceInstancesDropdown()
    {
        let context = this;
        context.serviceInstaceService.getServiceInstanceList(context.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        context.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: `${service.name} ${
                                    typeof service.customer !== "undefined" &&
                                    typeof service.customer.companyName !==
                                    "undefined"
                                        ? " - " + service.customer.companyName
                                        : ""
                                }`,
                            };
                        });
                        context.editServiceInstanceList = context.serviceInstanceList;
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    public getExtensionFile(typeExport: ExportFileType) {
        let context = this;
        if (typeExport === context.exportFileType.EXCEL) {
            return "xlsx";
        } else if (typeExport === context.exportFileType.PDF) {
            return "pdf";
        } else {
            return "xlsx";
        }
    }

    public exportFile(content: string, name: string, typeExport: ExportFileType) {
        let context = this;
        const linkSource = "data:application/octet-stream;base64," + content;
        const downloadLink = document.createElement("a");
        const fileName = "PIANO COLLEZIONE ALKENIUM " + name.replace(/[^a-zA-Z0-9 ]/g, '') + "." + context.getExtensionFile(typeExport);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public getList(event:LazyLoadEvent) {
        let context = this;
        if(event != null){
            context.event_lazy_local = event;
        }

        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;

        context.collectionPlanListLoading = true;
        context.collectionPlanService.getCollectionPlanList(context.token, context.event_lazy_local, serviceInstanceId)
            .pipe(
                tap((res) => {
                    if(res.data != null){
                        let slice = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;

                        context.collectionPlanList = slice.map((collectionPlan) => {
                            return {
                                id: collectionPlan.id,
                                serviceInstanceId: collectionPlan.serviceInstanceId,
                                serviceInstanceName: collectionPlan.serviceInstanceName,
                                header: collectionPlan.header,
                                details: collectionPlan.details,
                                versionNumber: collectionPlan.versionNumber
                            }
                        });
                        context.collectionPlanListLoading = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    public showCollectionPlan(id: string) {
        this.router.navigate(["collectionplans/detail/" + id]);
    }

    public showCollectionPlanProducts(id: string) {
        this.router.navigate(["collectionplans/" + id + "/products"]);
    }

    public exportCollectionPlan() {
        let context = this;
        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;
        context.collectionPlanExportLoading = true;
        context.exportCollectionPlanModal = false;

        context.collectionPlanService.exportCollectionPlan(context.token, serviceInstanceId, context.collectionPlan_export.id, context.collectionPlan_export.typeExport, null, context.selectedExportValue).pipe(
                tap((response) => {
                    if (typeof response.data !== 'undefined' && typeof response.data.content !== 'undefined'  && response.data.content !== '') {
                        context.exportFile(response.data.content, context.collectionPlan_export.headerName, context.collectionPlan_export.typeExport);
                        context.messageService.add({
                            severity: "success",
                            summary: "Success",
                            detail: response.message ?? "Exported the file successfully"
                        });
                    } else {
                        context.messageService.add({
                          severity: "error",
                          summary: "Error",
                          detail: "Could not export the file",
                        });
                        return of();
                    }
                }),catchError((error) => {
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: error.message ?? error.error.title
                    });
                    return of();
                }),finalize(() => {
                    context.collectionPlanExportLoading = false;
            })).subscribe();
    }

    public editInstanceCollectionPlan(id: string) {
        let context = this;
        context.collectionPlanDetailsService.getCollectionPlan(context.token, null, null, id).pipe(
            tap((response) => {
                context.collectionPlan_edit = {
                    id: response.data.id,
                    serviceInstanceId: response.data.serviceInstanceId,
                    header: response.data.header,
                    details: response.data.details,
                    versionNumber: response.data.versionNumber,
                } as CollectionPlan;
                context.editInstanceCollectionPlanModal = true;
            }),catchError((error) => {
                context.messageService.add({
                    severity:"error",
                    summary:"Error",
                    detail:error.message ?? error.error.title
                });
                context.editInstanceCollectionPlanModal = false;
                return of();
        }),finalize(() => {})).subscribe();

    }

    public closeEditInstanceCollectionPlan() {
        this.editInstanceCollectionPlanModal = false;
    }

    public updateInstanceCollectionPlan() {
        let context = this;
        context.collectionPlanService.updateCollectionPlanInstance(context.token, context.collectionPlan_edit.id, context.collectionPlan_edit.serviceInstanceId)
            .pipe(
                tap((res) => {
                    context.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: res.message ?? 'Success'
                    });
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {
                    context.getList(null);
                    context.closeEditInstanceCollectionPlan();
                })
            ).subscribe();
    }

    public openExportCollectionPlanModal(id: string, name: string, typeExport: ExportFileType) {
        let context = this;

        context.collectionPlan_export = {
            id: id,
            headerName: name,
            typeExport: typeExport
        };

        context.exportCollectionPlanModal = true;
    }

    public closeExportCollectionPlanModal() {
        this.exportCollectionPlanModal = false;
    }
}