import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseRequestMethod, BaseService } from "src/app/core/base/base.service";

@Injectable()
export class PermissionService {
    constructor(private http: HttpClient, private baseservice: BaseService)
    {

    }

    getListPermission(token:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "permissions/list", token, {});
    }


    getListUserPermissions(token:string, id:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "permissions/users/get", token, 
        {
            "userId": id
        });
    }

    setPermissionsCodeUser(token:string, id:string, permissionCodes:string[]): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "permissions/users/set", token,
        {
            "userId": id,
            "permissionCodes": permissionCodes
        });
    }

    unsetPermissionsCodeUser(token:string, id:string, permissionCodes: string[]): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "permissions/users/unset", token,
        {
            "userId": id,
            "permissionCodes": permissionCodes
        });
    }

    getListGroupPermissions(token:string, id:string):Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "permissions/groups/get", token,
        {
            "groupId":id
        });
    }

    setPermissionsCodeGroup(token:string, id:string, permissionCodes:string[]): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "permissions/groups/set", token,
        {
            "usersGroupId":id,
            "permissionCodes": permissionCodes
        });
    }

    unsetPermissionsCodeGroup(token:string, id:string, permissionCodes:string[]): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "permissions/groups/unset", token,
        {
            "usersGroupId":id,
            "permissionCodes": permissionCodes
        });
    }
}