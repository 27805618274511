import {Component} from '@angular/core';
import { AppGlobals } from 'src/app/app.globals';
import {AppComponent} from '../../app.component';
import {AppMainComponent} from '../../app.main.component';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-left">
                <!-- <a href="#" class="topbar-menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isOverlay() || appMain.isMobile()">
                    <i class="pi pi-bars"></i>
                </a> -->

                <!-- <a href="#" class="logo">
                    <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'">
                </a>

                <a href="#">
                    <img  [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name"/>
                </a> -->
            </div>

            <app-menu></app-menu>

            <div class="layout-topbar-right">
                <ul class="layout-topbar-right-items">
                    <li #profile class="profile-item" [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">
                        <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                            <img style="border-radius: 50%;" src="assets/layout/images/image-profile-2.jpeg">
                        </a>
                        <ul class="fadeInDown" style="padding: 0 0">
                        <li role="menuitem">
                                <a href="/#/home">
                                    <i class="pi pi-fw pi-home"></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="javascript:void(0);" (click)="appMain.logout()">
                                    <i class="pi pi-fw pi-sign-out"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li style="margin-right:10px;">Welcome back &nbsp;<b>{{currentUserName}}</b></li>
                </ul>
            </div>
        </div>
    `
})
export class AppTopbarComponent {
    public currentUserName: string;

    constructor(public app: AppComponent, public appMain: AppMainComponent) {
        this.getCurrentUserName();
    }

    private getCurrentUserName() {
        this.currentUserName = AppGlobals.storage().get("username");
      }
}
