<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left"></div>
            <div class="p-toolbar-group-right">
                <button pButton label="Create User" *ngIf="userHasPermission('CAN_ADD_USER')" (click)="createUser()" class="p-button-oris"></button>
            </div>
        </p-toolbar>
        <p-table #dtUsers [value]="usersList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
            [globalFilterFields]="['name', 'surname', 'username', 'email']"
            (onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
            [rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="usersListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Users List
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtUsers.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Name
                            <div>
                                <!-- <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> -->
                            <p-sortIcon field="name"></p-sortIcon>
                            </div>
                        </div>
                    </th>
                    <th pSortableColumn="surname">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Surname
                            <div>
                                <!-- <p-columnFilter type="text" field="surname" display="menu"></p-columnFilter> -->
                            <p-sortIcon field="surname"></p-sortIcon>
                            </div>
                        </div>
                    </th>
                    <th pSortableColumn="username">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Username
                            <div>
                                <!-- <p-columnFilter type="text" field="username" display="menu"></p-columnFilter> -->
                                <p-sortIcon field="username"></p-sortIcon>
                            </div>
                        </div>
                    </th>
                    <th pSortableColumn="email">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Email
                            <div>   
                                <!-- <p-columnFilter type="text" field="email" display="menu"></p-columnFilter> -->
                            <p-sortIcon field="email"></p-sortIcon>
                            </div>
                        </div>
                    </th>
                    <th style="width: 8rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>

                <tr class="p-selectable-row">
                    <td>
                        {{user.name}}
                    </td>
                    <td>
                        {{user.surname}}
                    </td>
                    <td>
                        {{user.username}}
                    </td>
                    <td>
                        {{user.email}}
                    </td>
                    <td>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EDIT_USER')" title="Edit" icon="pi pi-pencil"
									class="p-button-rounded p-button-text" (click)="editUser(user.id)"></button>
                                    <button pButton type="button"*ngIf="userHasPermission('CAN_VIEW_PERMISSIONS_LIST')" title="Permissions" icon="pi pi-key"
                            class="p-button-rounded p-button-text" (click)="permissionsUser(user.id)"></button>
						<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_USER')" title="Delete" icon="pi pi-trash"
                                    class="p-button-rounded p-button-text" (click)="deleteUser(user.id, user.username)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<form #createUserForm="ngForm" (ngSubmit)="createUserForm.form.valid && saveUser()">
    <p-dialog header="New User" [(visible)]="userCreateModal" [modal]="true" [style]="{width: '660px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <div class="p-formgroup-inline">
            <div class="p-col-6">
				<label for="userNew_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="user_new.serviceInstanceId" (onChange)="onChangeServiceInstance($event)"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serviceInstances" [baseZIndex]="10001" required optionValue="id" #service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="createUserForm.submitted && service_instance.invalid" class="error-login-info">
					<small *ngIf="service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
            <div class="p-col-6">
                <label for="userNew_groupUser">Group</label>
                <p-dropdown [options]="groupList" [(ngModel)]="user_new.userGroupId"
                [panelStyle]="{'overflow-y':'auto'}" placeholder="Select user group" optionLabel="name" [disabled]="disable_group"
                name="userGroups" [baseZIndex]="10001" required optionValue="id" #users_group='ngModel'>
            </p-dropdown>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userNew_roletoselect">Role</label>
                <p-dropdown [options]="roleToSelect" (onChange)="resetDropdowns($event)"
                #dropdown_roletoselect placeholder="Select possible role" [panelStyle]="{'overflow-y':'auto'}" [showClear]="true"
                optionLabel="label" name="roleToSelect" [baseZIndex]="10001" optionValue="id">
                </p-dropdown>
            </div>
            <div class="p-col-6">
                <div *ngIf="dropdown_roletoselect != undefined && dropdown_roletoselect.value == 1">
                    <label for="userNew_customer">Customer</label>
                    <p-dropdown [options]="customerList" [(ngModel)]="user_new.customerId"
                    [panelStyle]="{'overflow-y':'auto'}" placeholder="Select customer" optionLabel="companyName"
                    name="customers" [baseZIndex]="10001"  optionValue="id" #dropdown_customers='ngModel'>
                    </p-dropdown>
                </div>
                <div *ngIf="dropdown_roletoselect != undefined && dropdown_roletoselect.value == 2">
                    <label for="userNew_supplier">Supplier</label>
                    <p-dropdown [options]="supplierList" [(ngModel)]="user_new.supplierId"
                    [panelStyle]="{'overflow-y':'auto'}" placeholder="Select supplier" optionLabel="companyName"
                    name="suppliers" [baseZIndex]="10001"  optionValue="id" #dropdown_suppliers='ngModel'>
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userNew_name">Name</label>
                <input type="text" pInputText id="userNew_name" name="userNew_name" [(ngModel)]="user_new.name"
                    #name='ngModel' required />
                <div *ngIf="createUserForm.submitted && name.invalid" class="error-login-info">
                    <small *ngIf="name.errors?.required" class="p-error">Enter name</small>
                </div>
            </div>
            <div class="p-col-6">
                <label for="userNew_fileName">Surname</label>
                <input type="text" pInputText id="userNew_fileName" name="userNew_fileName"
                    [(ngModel)]="user_new.surname" #surname='ngModel' required />
                <div *ngIf="createUserForm.submitted && surname.invalid" class="error-login-info">
                    <small *ngIf="surname.errors?.required" class="p-error">Enter surname</small>
                </div>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userNew_email">Email</label>
                <input type="email" pInputText id="userNew_email" name="userNew_email" [(ngModel)]="user_new.email"
                    #email='ngModel' validateEmail="email" required />
                <div *ngIf="createUserForm.submitted && email.invalid" class="error-login-info">
                    <small *ngIf="email.errors?.required" class="p-error">Enter email</small>
                    <small *ngIf="email.errors?.invalidEmail && (email.touched || email.dirty) && email.value != ''"
                        class="p-error">Enter a valid email</small>
                </div>
            </div>
            <div class="p-col-6">
                <label for="userNew_username">Username</label>
                <input type="email" pInputText id="userNew_username" name="userNew_username"
                    [(ngModel)]="user_new.username" #username='ngModel' required />
                <div *ngIf="createUserForm.submitted && username.invalid" class="error-login-info">
                    <small *ngIf="username.errors?.required" class="p-error">Enter username</small>
                    <small
                        *ngIf="username.errors?.invalidEmail && (username.touched || username.dirty) && username.value != ''"
                        class="p-error">Enter a valid username</small>
                </div>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userNew_password">Password</label>
                <input type="password" pInputText id="userNew_password" name="userNew_password"
                    [(ngModel)]="user_new.password" #password='ngModel' minlength="8" validatePassword="password"
                    required />
                <div *ngIf="createUserForm.submitted && password.invalid " class="error-login-info">
                    <small
                        *ngIf="password.errors?.invalidPassword && (password.touched || password.dirty) && password.value != ''"
                        class="p-error">
                        Password must have at least: one lowercase letter, one uppercase letters, one number, and one
                        special characters
                    </small>
                    <small *ngIf="password.errors?.required" class="p-error">Enter password</small>
                </div>
            </div>
            <div class="p-col-6">
                <label for="userNew_confirmPassword">Confirm Password</label>
                <input type="password" pInputText id="userNew_confirmPassword" name="userNew_confirmPassword"
                    [(ngModel)]="user_new.confirmPassword" #confirm_password='ngModel' minlength="8" required />
                <div *ngIf="createUserForm.submitted && password.invalid" class="error-login-info">
                    <small *ngIf="confirm_password.errors?.required" class="p-error">Enter password</small>
                    <small *ngIf="password.value != confirm_password.value && confirm_password.value != ''"
                        class="p-error">Password does not match the confirm password</small>
                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" type="button" class="p-button-danger"
                (click)="closeCreateUserModal()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
        </ng-template>
    </p-dialog>
</form>

<form #editUserForm="ngForm" (ngSubmit)="editUserForm.form.valid && updateUser()">
    <p-dialog header="Update User" [(visible)]="userEditModal" [modal]="true" [style]="{width: '660px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <div class="p-formgroup-inline">
            <div class="p-col-6">
				<label for="userEdit_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="user_edit.serviceInstanceId"  (onChange)="onChangeServiceInstance($event)"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serverInstance" [baseZIndex]="10001" required optionValue="id" #edit_service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="editUserForm.submitted && edit_service_instance.invalid" class="error-login-info">
					<small *ngIf="edit_service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
            <div class="p-col-6">
                <label for="userEdit_groupUser">Group</label>
                <p-dropdown [options]="groupList" [(ngModel)]="user_edit.userGroupId"
                [panelStyle]="{'overflow-y':'auto'}" placeholder="Select user group" optionLabel="name" [disabled]="disable_group"
                name="userEdit_groupUser" [baseZIndex]="10001" required optionValue="id" #edit_users_group='ngModel'>
            </p-dropdown>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userEdit_roletoselect">Role</label>
                <p-dropdown [(ngModel)]="roleselected" [options]="roleToSelect" #edit_dropdown_roletoselect="ngModel" placeholder="Select possible role" (onChange)="resetDropdowns($event)" [panelStyle]="{'overflow-y':'auto'}" [showClear]="true"
                optionLabel="label" name="roleToSelect" [baseZIndex]="10001" optionValue="id">
                </p-dropdown>
            </div>
            <div class="p-col-6">
                <div *ngIf="edit_dropdown_roletoselect != undefined && edit_dropdown_roletoselect.value == 1">
                    <label for="userEdit_customer">Customer</label>
                    <p-dropdown [options]="customerList" [(ngModel)]="user_edit.customerId"
                    [panelStyle]="{'overflow-y':'auto'}" placeholder="Select customer" optionLabel="companyName"
                    name="customers" [baseZIndex]="10001"  optionValue="id" #edit_dropdown_customers='ngModel'>
                    </p-dropdown>
                </div>
                <div *ngIf="edit_dropdown_roletoselect != undefined && edit_dropdown_roletoselect.value == 2">
                    <label for="userEdit_supplier">Supplier</label>
                    <p-dropdown [options]="supplierList" [(ngModel)]="user_edit.supplierId"
                    [panelStyle]="{'overflow-y':'auto'}" placeholder="Select supplier" optionLabel="companyName"
                    name="suppliers" [baseZIndex]="10001"  optionValue="id" #edit_dropdown_suppliers='ngModel'>
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userEdit_name">Name</label>
                <input type="text" pInputText id="userEdit_name" name="userEdit_name" [(ngModel)]="user_edit.name"
                    #userEdit_name='ngModel' required />
                <div *ngIf="editUserForm.submitted && userEdit_name.invalid" class="error-login-info">
                    <small *ngIf="userEdit_name.errors?.required" class="p-error">Enter name</small>
                </div>
            </div>
            <div class="p-col-6">
                <label for="userEdit_surname">Surname</label>
                <input type="text" pInputText id="userEdit_surname" name="userEdit_surname"
                    [(ngModel)]="user_edit.surname" #userEdit_surname='ngModel' required />
                <div *ngIf="editUserForm.submitted && userEdit_surname.invalid" class="error-login-info">
                    <small *ngIf="userEdit_surname.errors?.required" class="p-error">Enter surname</small>
                </div>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userEdit_email">Email</label>
                <input type="email" pInputText id="userEdit_email" name="userEdit_email" [(ngModel)]="user_edit.email"
                    #userEdit_email='ngModel' validateEmail="email" required />
                <div *ngIf="editUserForm.submitted && userEdit_email.invalid" class="error-login-info">
                    <small *ngIf="userEdit_email.errors?.required" class="p-error">Enter email</small>
                    <small *ngIf="userEdit_email.errors?.invalidEmail && (userEdit_email.touched || userEdit_email.dirty) && userEdit_email.value != ''"
                        class="p-error">Enter a valid email</small>
                </div>
            </div>
            <div class="p-col-6">
                <label for="userEdit_username">Username</label>
                <input type="email" pInputText id="userEdit_username" name="userEdit_username"
                    [(ngModel)]="user_edit.username" #userEdit_username='ngModel' required />
                <div *ngIf="editUserForm.submitted && userEdit_username.invalid" class="error-login-info">
                    <small *ngIf="userEdit_username.errors?.required" class="p-error">Enter username</small>
                    <small
                        *ngIf="userEdit_username.errors?.invalidEmail && (userEdit_username.touched || userEdit_username.dirty) && userEdit_username.value != ''"
                        class="p-error">Enter a valid username</small>
                </div>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-col-6">
                <label for="userEdit_password">Password</label>
                <input type="password" pInputText id="userEdit_password" name="userEdit_password"
                    [(ngModel)]="user_edit.password" #password='ngModel'/>
            </div>
            <div class="p-col-6">
                <label for="userEdit_confirmPassword">Confirm Password</label>
                <input type="password" pInputText id="userEdit_confirmPassword" name="userEdit_confirmPassword"
                    [(ngModel)]="user_edit.confirmPassword" #userEdit_confirmPassword='ngModel' />
            </div>
        </div>
        <br />
        <br />
        <br />
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" type="button" class="p-button-danger"
                (click)="closeEditUserModal()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
        </ng-template>
    </p-dialog>
</form>

<form #formPermissions="ngForm">
    <input type="text" id="userPermission_id" name="userPermission_id" value="{{userPermissionId}}" hidden>
    <p-dialog header="User Permissions" [(visible)]="permissionModal" [modal]="true" [style]="{width:'500px'}" styleClass="p-fluid" [baseZIndex]="10000"
                [draggable]="false" [resizable]="false">
                <ng-template pTemplate="body">
                    <p-tree [value]="permissionsNodes" selectionMode="checkbox" [(selection)]="permissionsNodesSelected"
                    (onNodeSelect)="selectNode($event)" (onNodeUnselect)="unselectNode($event)"></p-tree>
                </ng-template>
                <ng-template pTemplate="footer">
                    <br/>
                    <br/>
                    <button pButton pRipple label="Close" icon="pi pi-times" type="button" class="p-button-danger"
                        (click)="closePermissionUser()"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"
                        (click)="savePermissions()"></button>
                </ng-template>
    </p-dialog>
</form>