import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';
import { User } from '../domain/user';

@Injectable()
export class UserService {

    constructor(private http: HttpClient, private baseservice: BaseService) {}
    
    loginAuthentication(username:string, password:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "users/authenticate", null, {username: username, password: password});
    }

    getUser(token:string, id:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "users/"+id, token, {});
    }

    createUser(token:string, user:User): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "users/create", token, 
        {
            username: user.username,
            email: user.email,
            name: user.name,
            surname: user.surname,
            password: user.password,
            serviceInstanceId: user.serviceInstanceId,
            usersGroupId: user.userGroupId,
            customerId: user.customerId,
            supplierId: user.supplierId
        });
    }

    updateUser(token:string, user:User): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, "users/update", token,
        {
            username: user.username,
            email: user.email,
            name: user.name,
            id: user.id,
            surname: user.surname,
            password: user.password,
            serviceInstanceId: user.serviceInstanceId,
            usersGroupId: user.userGroupId,
            customerId: user.customerId,
            supplierId: user.supplierId
        });
    }

    deleteUser(token:string, id:string): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, "users/"+id, token, {});
    }

    getUsersList(token:string, event:LazyLoadEvent): Observable<any>
    {
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "users", token, {
            filters:event?.filters ?? null,
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }
}